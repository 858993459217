<template>
  <CCard>
    <CCardHeader>
      <div class="card-header-actions">
        <CButton variant="outline" color="success" @click="onReset" ref="reset-button" v-translate
                 translate-context="form" class="mr-2">Reset
        </CButton>
        <CButton color="primary" @click="onSave" ref="save-button">{{ save_text }}</CButton>
      </div>
    </CCardHeader>
    <CCardBody class="p-0">
      <CContainer fluid class="grid-stripped">
        <PFRow :label="$pgettext('vertical_configuration.label', 'Vertical name')">
          <CInput v-model="form.name" :placeholder="$pgettext('vertical_configuration.placeholder','Name')"/>
        </PFRow>
        <PFRow :label="$pgettext('vertical_configuration.label', 'Category')">
          <CSelect v-model="form.category" :options="categories" :placeholder="$gettext('- Select -')"/>
        </PFRow>
        <PFRow :label="$pgettext('vertical_configuration.label', 'Codec')">
          <CSelect v-model="form.codec" :options="codecs"/>
        </PFRow>
        <PFRow :label="$pgettext('vertical_configuration.label', 'Magic Tag Enabled')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.is_magictag_enabled"/>
        </PFRow>
        <PFRow :label="$pgettext('vertical_configuration.label', 'Recognition Class')">
          <CSelect v-model="form.recognition_class" :options="recognitionClasses"/>
        </PFRow>
        <PFRow :label="$pgettext('vertical_configuration.label', 'Face Recognition - min confidence (0.00 - 1.00)')">
          <CInput v-model="form.face_recognition_min_confidence" type="number" min="0" step="0.01"/>
        </PFRow>
        <PFRow :label="$pgettext('vertical_configuration.label', 'Face Match - min confidence (0.00 - 1.00)')">
          <CInput v-model="form.face_match_min_confidence" type="number" min="0" step="0.01"/>
        </PFRow>
        <PFRow :label="$pgettext('vertical_configuration.label', 'Num selfies (1 = no multy-selfy)')">
          <CInput v-model="form.checkin_max_faces_count" type="number" min="1"/>
        </PFRow>
        <PFRow :label="$pgettext('vertical_configuration.label', 'Checkin Stay Mode')">
          <CSelect v-model="form.checkin_stay_mode" :options="checkinStayMode"/>
        </PFRow>
        <PFRow :label="$pgettext('vertical_configuration.label', 'Allow upload from mobile')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.allows_upload_from_mobile"/>
        </PFRow>
        <PFRow :label="$pgettext('vertical_configuration.label', 'Require a Welcome Code')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.requires_welcome_code"/>
        </PFRow>
        <template v-if="form.requires_welcome_code">
          <PFRow :label="$pgettext('vertical_configuration.label', 'Auto generate welcome code at publish')">
            <CSwitch class="mx-1" color="primary" variant="3d" size="sm"
                     :checked.sync="form.auto_generate_welcome_code"/>
          </PFRow>
          <template v-if="!form.auto_generate_welcome_code">
            <PFRow :label="$pgettext('vertical_configuration.label', 'Allows custom welcome code')">
              <CSwitch class="mx-1" color="primary"
                       variant="3d" size="sm"
                       :checked.sync="form.allows_custom_welcome_code"/>
            </PFRow>
          </template>
        </template>
        <PFRow :label="$pgettext('vertical_configuration.label', 'Allow TimeLine in browse photo')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm"
                   :checked.sync="form.allows_browse_photo_timeline"/>
        </PFRow>
        <PFRow :label="$pgettext('vertical_configuration.label', 'Allow upload HD')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.allows_upload_hd"/>
        </PFRow>
        <PFRow :label="$pgettext('vertical_configuration.label', 'Allow upload video')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.allows_upload_video"/>
        </PFRow>
        <PFRow :label="$pgettext('vertical_configuration.label', 'Has media original')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.has_media_original"/>
        </PFRow>
        <PFRow :label="$pgettext('vertical_configuration.label', 'Anonymize Mode')">
          <CSelect v-model="form.checkin_anonymize_mode" :options="anonymizeMode"/>
        </PFRow>
        <PFRow :label="$pgettext('vertical_configuration.label', 'Experience Type')">
          <CSelect v-model="form.experience_type" :options="experienceType"/>
        </PFRow>
        <PFRow :label="$pgettext('vertical_configuration.label', 'Prevent checkin for existing faces')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm"
                   :checked.sync="form.prevent_checkins_for_existing_faces"/>
        </PFRow>
        <PFRow :label="$pgettext('vertical_configuration.label', 'Purchase Time')">
          <CSelect v-model="form.purchase_time" :options="purchaseTime"/>
        </PFRow>
        <PFRow
            :label="$pgettext('vertical_configuration.label', 'Face reassignment allowed')"
            help-text="When enabled, faces already assigned to a FaceCheckin can be reassigned">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm"
                   :checked.sync="form.is_face_reassignment_allowed"/>
        </PFRow>
        <PFRow
            :label="$pgettext('vertical_configuration.label', 'Disable slave checkin')"
            help-text="Only one user will be able to check-in">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm"
                   :checked.sync="form.checkin_slave_disabled"/>
        </PFRow>
        <PFRow><h3>Video Recognition</h3></PFRow>
        <PFRow
            :label="$pgettext('vertical_configuration.label', 'Video Sample FPS')"
            help-text="Frame to sample for every second (0.1 -3.0)">
          <CInput v-model="form.video_sample_fps" type="number" min="0.1" max="3.0" step="0.1"/>
        </PFRow>
                <PFRow
            :label="$pgettext('vertical_configuration.label', 'Transcode')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm"
                   :checked.sync="form.video_transcode"/>
        </PFRow>
      </CContainer>
    </CCardBody>
  </CCard>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PFRow from "@/domain/core/components/PFRow.vue";

export default {
  name: "Form",
  components: {PFRow},
  props: {
    data: {
      type: Object,
      required: true
    },
    save_text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      form: {
        name: '',
        category: null,
        codec: 'FaceRecognition262kCodec',
        is_magictag_enabled: false,
        recognition_class: 'person',
        face_recognition_min_confidence: 0.95,
        face_match_min_confidence: 0.97,
        checkin_max_faces_count: 1,
        checkin_stay_mode: 'none',
        allows_upload_from_mobile: false,
        allows_custom_welcome_code: false,
        requires_welcome_code: false,
        auto_generate_welcome_code: false,
        allows_browse_photo_timeline: false,
        allows_upload_hd: false,
        allows_upload_video: false,
        has_media_original: false,
        checkin_anonymize_mode: 'blur',
        experience_type: 'p',
        prevent_checkins_for_existing_faces: false,
        purchase_time: 'at',
        is_face_reassignment_allowed: false,
        checkin_slave_disabled: true,
        video_sample_fps: 1.0,
        video_transcode: true,
      },
    }
  },
  async mounted() {
    await Promise.all([
      this.fetchRecognitionClasses(),
      this.fetchCategories(),
      this.fetchCodecs(),
      this.fetchCheckinStayMode(),
      this.fetchAnonymizeMode(),
      this.fetchExperienceType(),
      this.fetchPurchaseTime()
    ])
    this.onReset()
  },
  emits: ['save'],
  computed: {
    ...mapGetters('core', [
      'recognitionClasses',
      'categories',
      'codecs',
      'checkinStayMode',
      'anonymizeMode',
      'experienceType',
      'purchaseTime',
    ]),
  },
  methods: {
    ...mapActions('core', [
      'fetchRecognitionClasses',
      'fetchCategories',
      'fetchCodecs',
      'fetchCheckinStayMode',
      'fetchAnonymizeMode',
      'fetchExperienceType',
      'fetchPurchaseTime'
    ]),
    onReset() {
      this.form = {...this.data}
    },
    async onSave() {
      this.$emit('save', this.form)
    },
  }
}
</script>