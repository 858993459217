<template>
  <div>
    <h1 class="main-header" v-translate translate-context="form'">Create</h1>
    <Form :data=empty @save="onSave" :save_text="$pgettext('form','Create')"/>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import Form from "@/domain/verticalConfiguration/components/Form.vue";
import {SuccessNotification} from "@/domain/core/exception/exceptions";

export default {
  name: 'NewVerticalConfiguration',
  components: {Form},
  data() {
    return {
      empty: {
        name: '',
        category: null,
        codec: 'FaceRecognition262kCodec',
        is_magictag_enabled: false,
        recognition_class: 'person',
        face_recognition_min_confidence: 0.95,
        face_match_min_confidence: 0.97,
        checkin_max_faces_count: 1,
        checkin_stay_mode: 'none',
        allows_upload_from_mobile: false,
        allows_custom_welcome_code: false,
        requires_welcome_code: false,
        auto_generate_welcome_code: false,
        allows_browse_photo_timeline: false,
        allows_upload_hd: false,
        allows_upload_video: false,
        has_media_original: false,
        checkin_anonymize_mode: 'blur',
        experience_type: 'p',
        prevent_checkins_for_existing_faces: false,
        purchase_time: 'at',
        is_face_reassignment_allowed: false,
        checkin_slave_disabled: true,
        video_sample_fps: 1.0,
        video_transcode: true,
      }
    }
  },
  methods: {
    ...mapActions('verticalConfiguration', [
      'createVertical',
    ]),
    async onSave(data) {
      await this.createVertical(data)
      await this.$router.push({name: 'verticalConfiguration.list'})
      throw new SuccessNotification(this.$pgettext('vertical_configuration', 'Vertical Configuration created!'))
    }
  }
}
</script>